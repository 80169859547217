import '../styles/home.css'
import '../styles/constants/divs.css'
import '../styles/constants/typography.css'
import React, { useState } from 'react';
import axios from "axios";

export default function Home({}) {


// #region CONSTANTS & STATE VARIABLES
const [emailText,setEmailText] = useState('')
const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
const [submitted,setSubmitted] = useState(false)

// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS
const saveEmailToDB = () => {

    axios.get(baseURL+'addUserEmailToWaitingList',{
        
        params:{
            email: emailText
        }

    
    }).then( (response) => {
    
        const data = response.data;
        console.log("Response", response,data)
               
    });
}
// #endregion


// #region BUTTONS CLICKED
const submitEmail = () => {

    if(emailText == ""){console.log("Invalid email")}
    else{
        saveEmailToDB()
        setEmailText("")
        setSubmitted(true)
    }


}
// #endregion


// #region OTHER
   
// #endregion



    

    return(
        <div className='height100'>
            
            <div className='homeTopSection'>
                <img className='lightHome' src='/assets/lightbulb.jpg'/>
                <div className='homeTSText'>
                    <div className='column ai-centre gap8'>
                        <span className='display-2xl bold'>KODUU</span>
                        <span className='text-xl bold marginTopMinus20'>R+D STUDIO</span>
                    </div>
                    

                    <span className='display-sm medium'>Turning your ideas into live products</span>

                    <div className='row gap12'>
                        <div className='row emailInput ai-centre'>
                            <img className='emailIV' src='assets/emailIcon.png'/>
                            <input className='matchParent emailTextEdit smallText' type="text" placeholder={"Enter email"} value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                        </div>
                        <button className={`getUpdatesBtn smallText ${submitted?"updatesBtnSubmitted":""}`} onClick={submitEmail}>{submitted?"Submitted":"Get Updates"}</button>
                        
                    </div>
                    
                </div>
            </div>

            
        </div>
    )

}