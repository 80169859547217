import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route,Routes,Navigate} from 'react-router-dom'
import Home from './screens/Home';
import Test from './screens/Test';

function App() {
  return (
    <div className="App">
      
      <Router>

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/test' element={<Test/>} />
          </Routes>

      </Router>

      
    </div>
  );
}

export default App;
