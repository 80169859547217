
import React, {} from 'react';

export default function Test({}) {


// #region CONSTANTS & STATE VARIABLES

// #endregion


// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED
  
// #endregion


// #region OTHER
   
// #endregion



    

    return(
        <div>
            
            <h1>Welcome to Test Screen</h1>

            
        </div>
    )

}